import { endpoints, TriangleConfig, TRIANGLE_VERSION } from './environment-commons';

export const environment: TriangleConfig = {
  name: 'test',
  production: true,
  api: {
    // TODO: Update based on actual API Gateway URL!
    baseUrl: 'https://api.test.triangle.te-ded.com/',
    endpoints,
  },
  cognito: {
    // TODO: Update based on AWS (for Triangle) config!
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_Zch9adQ7P',
      userPoolWebClientId: '1lk76vou4mn61jo30sdo24to5h',
      userPoolWebClientSecret: '',
      oauth: {
        domain: 'triangle-test.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://test.triangle.te-ded.com/home',
        redirectSignOut: 'https://test.triangle.te-ded.com/home',
        responseType: 'code',
        options: {
          AdvancedSecurityDataCollectionFlag: true,
        },
        identityProvider: 'triangle-test-idp',
      },
    },
  },
  sentryDsn: 'https://be8e7d5570d2403baab7c31e3b71411e@o258615.ingest.sentry.io/5764718',
  googleApiKey: 'AIzaSyDcXblgOQzvPIStGtyc62E6y3Ljo2S7PGM',
  captchaSiteKey: '6LdpANAZAAAAADttfKCLyaY6Uic-Q0l0Y2L3_rVL',
  http: {
    timeoutMs: 300000,
    maxRetryAttempts: 2,
    retryScalingDuration: 1000,
    retryIncludedStatusCodes: [401],
    refreshTokenIncludedStatusCodes: [401],
  },
  serviceDeskUrl: 'https://tractebel-et.atlassian.net/jira/servicedesk/projects/TRSD/issues',
  version: TRIANGLE_VERSION,
};
